module.exports = {
    fabricArray: [
        [
            {
                name: 'Black- 265g',
                img: require('@/assets/img/fabric/Black- 265g.jpeg')
            },
            {
                name: 'Black- 280G',
                img: require('@/assets/img/fabric/Black- 280G.jpeg')
            },
            {
                name: 'blue check 265g',
                img: require('@/assets/img/fabric/blue check 265g.jpeg')
            }
        ],
        [
            {
                name: 'light blue',
                img: require('@/assets/img/fabric/light blue.jpeg')
            },
            {
                name: 'solid - white',
                img: require('@/assets/img/fabric/solid - white.jpeg')
            }
        ]
    ],
    styleObject: {
        jacket: {
            lapel: [
                [
                    {
                        name: 'Notch lapel',
                        img: require('@/assets/img/style/jacket/lapel style -1.png')
                    },
                    {
                        name: 'Peak lapel',
                        img: require('@/assets/img/style/jacket/lapel style -2.png')
                    },
                    {
                        name: 'Shawl lapel',
                        img: require('@/assets/img/style/jacket/lapel style -3.png')
                    }
                ]
            ],
            frontButton: [
                [
                    {
                        name: 'Single breast (1 button)',
                        img: require('@/assets/img/style/jacket/front button-1.png')
                    },
                    {
                        name: 'Single breast (2 buttons)',
                        img: require('@/assets/img/style/jacket/front button-2.png')
                    },
                    {
                        name: 'Double breast (4x 1, one fasten)',
                        img: require('@/assets/img/style/jacket/front button-3.png')
                    },
                    {
                        name: 'Double breast (4x 2, two fasten)',
                        img: require('@/assets/img/style/jacket/front button-4.png')
                    }
                ],
                [
                    {
                        name: 'Double breast (6x 2, two fasten)',
                        img: require('@/assets/img/style/jacket/front button-5.png')
                    }
                ]
            ],
            lowerPocket: [
                [
                    {
                        name: 'Flap pocket',
                        img: require('@/assets/img/style/jacket/lower pocket -1.png')
                    },
                    {
                        name: 'Flap pocket with ticket',
                        img: require('@/assets/img/style/jacket/lower pocket -4.png')
                    },
                    {
                        name: 'Flap Slant',
                        img: require('@/assets/img/style/jacket/lower pocket -2.png')
                    },
                    {
                        name: 'Flap Slant with ticket',
                        img: require('@/assets/img/style/jacket/lower pocket -5.png')
                    }
                ],
                [
                    {
                        name: 'Flap pocket with double besom ticket'
                    },
                    {
                        name: 'Double besom pocket',
                        img: require('@/assets/img/style/jacket/lower pocket -6.png')
                    },
                    {
                        name: 'Patch pocket',
                        img: require('@/assets/img/style/jacket/lower pocket -3.png')
                    }
                ]
            ],
            breastPocket: [
                [
                    {
                        name: 'Normal Breast Pocket',
                        img: require('@/assets/img/style/jacket/Breast pocket-1.png')
                    },
                    {
                        name: 'Patch Breast pocket',
                        img: require('@/assets/img/style/jacket/Breast pocket-2.png')
                    }
                ]
            ],
            backVent: [
                [
                    {
                        name: 'No vent'
                    },
                    {
                        name: 'Central Vent',
                        img: require('@/assets/img/style/jacket/Back vent style -1.png')
                    },
                    {
                        name: 'Side vents',
                        img: require('@/assets/img/style/jacket/Back vent style -2.png')
                    },
                    {
                        name: 'Side vents with strap',
                        img: require('@/assets/img/style/jacket/Back vent style -3.png')
                    }
                ]
            ],
            pickStitching: [
                [
                    {
                        name: 'No Pick stitch',
                        img: require('@/assets/img/style/jacket/Pick stitching-1.png')
                    },
                    {
                        name: 'With Pick stitch',
                        img: require('@/assets/img/style/jacket/Pick stitching-2.png')
                    }
                ]
            ],
            sleeveSlitButton: [
                [
                    {
                        name: 'Real Sleeve Slit， functional buttonhole, 4 flat buttons',
                        img: require('@/assets/img/style/jacket/Sleeve button and Slit style-1.png')
                    },
                    {
                        name: 'Real Sleeve Slit， functional Slant buttonhole, 4 kissing buttons',
                        img: require('@/assets/img/style/jacket/Sleeve button and Slit style-2.png')
                    },
                    {
                        name: 'Normal sleeve Slit, non-functional buttonhole, 4 flat buttons    ',
                        img: require('@/assets/img/style/jacket/Sleeve button and Slit style-3.png')
                    },
                    {
                        name: 'Normal sleeve Slit, non-functional buttonhole, 4 kissing buttons',
                        img: require('@/assets/img/style/jacket/Sleeve button and Slit style-4.png')
                    }
                ]
            ],
            tuxedo: [
                [
                    {
                        name: 'Satin lapel，Satin lower pocket besom， Satin covered button',
                        img: require('@/assets/img/style/jacket/tuxedo style.png')
                    }
                ]
            ]
        },
        pant: {
            frontPleats: [
                [
                    {
                        name: 'No Pleats',
                        img: require('@/assets/img/style/pant/front pleats style- 1.png')
                    },
                    {
                        name: 'Single Pleats',
                        img: require('@/assets/img/style/pant/front pleats style- 2.png')
                    },
                    {
                        name: 'Double Pleats',
                        img: require('@/assets/img/style/pant/front pleats style- 3.png')
                    }
                ]
            ],
            frontPocket: [
                [
                    {
                        name: '2.0cm Slant pocket',
                        img: require('@/assets/img/style/pant/front pocket -1.png')
                    },
                    {
                        name: '2.5cm Slant pocket',
                        img: require('@/assets/img/style/pant/front pocket -2.png')
                    },
                    {
                        name: '3.2cm Slant pocket',
                        img: require('@/assets/img/style/pant/front pocket -3.png')
                    },
                    {
                        name: 'Diamond Jean pocket',
                        img: require('@/assets/img/style/pant/front pocket -4.png')
                    }
                ],
                [
                    {
                        name: 'Sideseam pocket',
                        img: require('@/assets/img/style/pant/front pocket -5.png')
                    },
                    {
                        name: 'Double besom',
                        img: require('@/assets/img/style/pant/front pocket -6.png')
                    }
                ]
            ],
            beltLoop: [
                [
                    {
                        name: 'No belt loop',
                        img: require('@/assets/img/style/pant/belt loop-1 .png')
                    },
                    {
                        name: 'Regular belt loops',
                        img: require('@/assets/img/style/pant/belt loop-2.png')
                    }
                ]
            ],
            backPocket: [
                [
                    {
                        name: 'Double besom',
                        img: require('@/assets/img/style/pant/Back pocket- 1.png')
                    },
                    {
                        name: 'Double besom with button',
                        img: require('@/assets/img/style/pant/Back pocket- 2.png')
                    },
                    {
                        name: 'Double besom with tab& button',
                        img: require('@/assets/img/style/pant/Back pocket- 3.png')
                    }
                ]
            ],
            sideWaistband: [
                [
                    {
                        name: 'Standard waistband',
                        img: require('@/assets/img/style/pant/Side Waistband-1.png')
                    },
                    {
                        name: 'Adjust Buckle dark color',
                        img: require('@/assets/img/style/pant/Side Waistband-2.png')
                    },
                    {
                        name: 'Adjust Buckle silver color',
                        img: require('@/assets/img/style/pant/Side Waistband-3.png')
                    },
                    {
                        name: 'Adjust Button',
                        img: require('@/assets/img/style/pant/Side Waistband-4.png')
                    }
                ],
                [
                    {
                        name: 'Adjust tab',
                        img: require('@/assets/img/style/pant/Side Waistband-5.png')
                    }
                ]
            ],
            innerWaistband: [
                [
                    {
                        name: 'Standard inner waistband',
                        img: require('@/assets/img/style/pant/Inner Waistband.png')
                    },
                    {
                        name: 'Suspend button',
                        img: require('@/assets/img/style/pant/Inner Waistband.png')
                    },
                    {
                        name: 'Pleat with Snugtex',
                        img: require('@/assets/img/style/pant/Inner Waistband.png')
                    },
                    {
                        name: 'Standard with snugtex& piping with pant lining',
                        img: require('@/assets/img/style/pant/Inner Waistband.png')
                    }
                ]
            ],
            pantBottom: [
                [
                    {
                        name: 'Plain 5cm',
                        img: require('@/assets/img/style/pant/Pant bottom -1.png')
                    },
                    {
                        name: 'Slant with 4cm inner edgefold',
                        img: require('@/assets/img/style/pant/Pant bottom -2.png')
                    },
                    {
                        name: 'Unfinished with 10cm extra fabric',
                        img: require('@/assets/img/style/pant/Pant bottom -3.png')
                    },
                    {
                        name: 'Cuff hem 3.8cm',
                        img: require('@/assets/img/style/pant/Pant bottom -4.png')
                    }
                ],
                [
                    {
                        name: 'Cuff hem 3.2cm ',
                        img: require('@/assets/img/style/pant/Pant bottom -5.png')
                    }
                ]
            ],
            pantLining: [
                [
                    {
                        name: 'front half lined',
                        img: require('@/assets/img/style/pant/Pant lining-1.png')
                    },
                    {
                        name: 'Fully lined at front',
                        img: require('@/assets/img/style/pant/Pant lining-2.png')
                    },
                    {
                        name: 'Fully lined',
                        img: require('@/assets/img/style/pant/Pant lining-3.png')
                    },
                    {
                        name: 'No lining',
                        img: require('@/assets/img/style/pant/Pant lining-4.png')
                    }
                ]
            ]
        },
        vest: {
            collar: [
                [
                    {
                        name: 'V shape',
                        img: require('@/assets/img/style/vest/Collar style-1.png')
                    },
                    {
                        name: 'U shape',
                        img: require('@/assets/img/style/vest/Collar style-1.png')
                    }
                ]
            ],
            frontButton: [
                [
                    {
                        name: 'Single breast ( 5 buttons )',
                        img: require('@/assets/img/style/vest/front button style -1.png')
                    },
                    {
                        name: 'Single breast ( 4 buttons)'
                    },
                    {
                        name: 'Double breast (6x3 buttons)',
                        img: require('@/assets/img/style/vest/front button style -1.png')
                    }
                ]
            ],
            bottom: [
                [
                    {
                        name: 'Pointed bottom',
                        img: require('@/assets/img/style/vest/Bottom style-1.png')
                    },
                    {
                        name: 'Square bottom',
                        img: require('@/assets/img/style/vest/Bottom style-2.png')
                    }
                ]
            ],
            lowerBreastPocket: [
                [
                    {
                        name: 'Welt lower pocket',
                        img: require('@/assets/img/fabric/solid - white.jpeg')
                    },
                    {
                        name: 'Double besom lower pocket',
                        img: require('@/assets/img/fabric/solid - white.jpeg')
                    },
                    {
                        name: 'Welt lower pocket with breast pocket',
                        img: require('@/assets/img/fabric/solid - white.jpeg')
                    },
                    {
                        name: 'Double besom lower pocket with breast pocket',
                        img: require('@/assets/img/fabric/solid - white.jpeg')
                    },
                    {
                        name: 'Double besom lower pocket with Welt pocket',
                        img: require('@/assets/img/fabric/solid - white.jpeg')
                    }
                ]
            ],
            inner: [
                [
                    {
                        name: 'With Appointed lining- ',
                        img: require('@/assets/img/style/vest/inner style.png')
                    },
                    {
                        name: 'Matching fabric color lining- ',
                        img: require('@/assets/img/style/vest/inner style.png')
                    }
                ]
            ],
            back: [
                [
                    {
                        name: 'Back with strap& lining- ',
                        img: require('@/assets/img/style/vest/Back style- 1.png')
                    },
                    {
                        name: 'Back with strap& back use fabric',
                        img: require('@/assets/img/style/vest/Back style- 2.png')
                    },
                    {
                        name: 'Back no strap& lining- ',
                        img: require('@/assets/img/style/vest/Back style- 3.png')
                    },
                    {
                        name: 'Back no strap & back use fabric',
                        img: require('@/assets/img/style/vest/Back style- 4.png')
                    }
                ]
            ],
            tuxedo: [
                [
                    {
                        name: 'Satin lower pocket besom',
                        img: require('@/assets/img/style/vest/tuxedo style.png')
                    },
                    {
                        name: 'Satin covered button',
                        img: require('@/assets/img/style/vest/tuxedo style.png')
                    }
                ]
            ]
        }
    },
    embroideryObject: {
        jacket: {
            font: [
                [
                    {
                        name: 'Calligraphy',
                        img: require('@/assets/img/embroidery/jacket/Embroidery Font-1.png')
                    },
                    {
                        name: 'Times Roman',
                        img: require('@/assets/img/embroidery/jacket/Embroidery Font-2.png')
                    },
                    {
                        name: 'Block',
                        img: require('@/assets/img/embroidery/jacket/Embroidery Font-3.png')
                    },
                    {
                        name: 'Script',
                        img: require('@/assets/img/embroidery/jacket/Embroidery Font-4.png')
                    }
                ]
            ]
        }
    },
    selectOptions: {
        type: [
            {value: '', text: 'Please select'},
            {value: '2pcs', text: '2pcs'},
            {value: '3pcs', text: '3pcs'},
            {value: 'jacket', text: 'Jacket'},
            {value: 'pant', text: 'Pant'},
            {value: 'vest', text: 'Vest'}
        ],
        heightUnit: [
            {value: 'inch', text: 'inch'},
            {value: 'cm', text: 'cm'}
        ],
        weightUnit: [
            {value: 'kg', text: 'kg'},
            {value: 'pound', text: 'pound'}
        ],
        style: {
            jacket: {
                lapel: [
                    {value: '', text: 'Please select'},
                    {value: 'Notch lapel', text: 'Notch lapel'},
                    {value: 'Peak lapel', text: 'Peak lapel'},
                    {value: 'Shawl lapel', text: 'Shawl lapel'}
                ],
                frontButton: [
                    {value: '', text: 'Please select'},
                    {value: 'Single breast (1 button)', text: 'Single breast (1 button)'},
                    {value: 'Single breast (2 buttons)', text: 'Single breast (2 buttons)'},
                    {value: 'Double breast (4x 1, one fasten)', text: 'Double breast (4x 1, one fasten)'},
                    {value: 'Double breast (4x 2, two fasten)', text: 'Double breast (4x 2, two fasten)'},
                    {value: 'Double breast (6x 2, two fasten)', text: 'Double breast (6x 2, two fasten)'}
                ],
                lowerPocket: [
                    {value: '', text: 'Please select'},
                    {value: 'Flap pocket', text: 'Flap pocket'},
                    {value: 'Flap pocket with ticket', text: 'Flap pocket with ticket'},
                    {value: 'Flap Slant', text: 'Flap Slant'},
                    {value: 'Flap Slant with ticket', text: 'Flap Slant with ticket'},
                    {value: 'Flap pocket with double besom ticket', text: 'Flap pocket with double besom ticket'},
                    {value: 'Double besom pocket', text: 'Double besom pocket'},
                    {value: 'Patch pocket', text: 'Patch pocket'}
                ],
                breastPocket: [
                    {value: '', text: 'Please select'},
                    {value: 'Normal Breast Pocket', text: 'Normal Breast Pocket'},
                    {value: 'Patch Breast pocket', text: 'Patch Breast pocket'}
                ],
                backVent: [
                    {value: '', text: 'Please select'},
                    {value: 'No vent', text: 'No vent'},
                    {value: 'Central Vent', text: 'Central Vent'},
                    {value: 'Side vents', text: 'Side vents'},
                    {value: 'Side vents with strap', text: 'Side vents with strap'}
                ],
                pickStitching: [
                    {value: '', text: 'Please select'},
                    {value: 'No Pick stitch', text: 'No Pick stitch'},
                    {value: 'With Pick stitch', text: 'With Pick stitch'}
                ],
                sleeveSlitButton: [
                    {value: '', text: 'Please select'},
                    {
                        value: 'Real Sleeve Slit， functional buttonhole, 4 flat buttons',
                        text: 'Real Sleeve Slit， functional buttonhole, 4 flat buttons'
                    },
                    {
                        value: 'Real Sleeve Slit， functional Slant buttonhole, 4 kissing buttons',
                        text: 'Real Sleeve Slit， functional Slant buttonhole, 4 kissing buttons'
                    },
                    {
                        value: 'Normal sleeve Slit, non-functional buttonhole, 4 flat buttons',
                        text: 'Normal sleeve Slit, non-functional buttonhole, 4 flat buttons'
                    },
                    {
                        value: 'Normal sleeve Slit, non-functional buttonhole, 4 kissing buttons',
                        text: 'Normal sleeve Slit, non-functional buttonhole, 4 kissing buttons'
                    }
                ],
                tuxedo: [
                    {value: '', text: 'Please select'},
                    {
                        value: 'Satin lapel，Satin lower pocket besom， Satin covered button',
                        text: 'Satin lapel，Satin lower pocket besom， Satin covered button'
                    }
                ]
            },
            pant: {
                frontPleats: [
                    {value: '', text: 'Please select'},
                    {value: 'No Pleats', text: 'No Pleats'},
                    {value: 'Single Pleats', text: 'Single Pleats'},
                    {value: 'Double Pleats', text: 'Double Pleats'}
                ],
                frontPocket: [
                    {value: '', text: 'Please select'},
                    {value: '2.0cm Slant pocket', text: '2.0cm Slant pocket'},
                    {value: '3.2cm Slant pocket', text: '3.2cm Slant pocket'},
                    {value: 'Diamond Jeans pocket', text: 'Diamond Jeans pocket'},
                    {value: 'Sideseam Pocket', text: 'Sideseam Pocket'}
                ],
                beltLoop: [
                    {value: '', text: 'Please select'},
                    {value: 'No belt loop', text: 'No belt loop'},
                    {value: 'Regular belt loops', text: 'Regular belt loops'}
                ],
                backPocket: [
                    {value: '', text: 'Please select'},
                    {value: 'Double besom', text: 'Double besom'},
                    {value: 'Double besom with button', text: 'Double besom with button'},
                    {value: 'Double besom with tab& button', text: 'Double besom with tab& button'}
                ],
                sideWaistband: [
                    {value: '', text: 'Please select'},
                    {value: 'Standard waistband', text: 'Standard waistband'},
                    {value: 'Adjust Buckle', text: 'Adjust Buckle'},
                    {value: 'Adjust Button', text: 'Adjust Button'},
                    {value: 'Adjust tab', text: 'Adjust tab'}
                ],
                innerWaistband: [
                    {value: '', text: 'Please select'},
                    {value: 'Standard inner waistband', text: 'Standard inner waistband'},
                    {value: 'Suspend button', text: 'Suspend button'},
                    {value: 'Pleat with Snugtex', text: 'Pleat with Snugtex'},
                    {
                        value: 'Standard with snugtex& piping with pant lining',
                        text: 'Standard with snugtex& piping with pant lining'
                    }
                ],
                pantBottom: [
                    {value: '', text: 'Please select'},
                    {value: 'Plain 5cm', text: 'Plain 5cm'},
                    {value: 'Slant with 4cm inner edgefold', text: 'Slant with 4cm inner edgefold'},
                    {value: 'Cuff hem 3.2cm or 3.8cm', text: 'Cuff hem 3.2cm or 3.8cm'},
                    {value: 'Unfinished with 10cm extra fabric', text: 'Unfinished with 10cm extra fabric'}
                ],
                pantLining: [
                    {value: '', text: 'Please select'},
                    {value: 'front half lined', text: 'front half lined'},
                    {value: 'Fully lined at front', text: 'Fully lined at front'},
                    {value: 'Fully lined', text: 'Fully lined'},
                    {value: 'No lining', text: 'No lining'}
                ]
            },
            vest: {
                collar: [
                    {value: '', text: 'Please select'},
                    {value: 'V shape', text: 'V shape'},
                    {value: 'U shape', text: 'U shape'}
                ],
                frontButton: [
                    {value: '', text: 'Please select'},
                    {value: 'Single breast ( 5 buttons )', text: 'Single breast ( 5 buttons )'},
                    {value: 'Single breast ( 4 buttons)', text: 'Single breast ( 4 buttons)'},
                    {value: 'Double breast (6x3 buttons)', text: 'Double breast (6x3 buttons)'}
                ],
                bottom: [
                    {value: '', text: 'Please select'},
                    {value: 'Pointed bottom', text: 'Pointed bottom'},
                    {value: 'Square bottom', text: 'Square bottom'}
                ],
                lowerPocket: [
                    {value: '', text: 'Please select'},
                    {value: 'Welt lower pocket', text: 'Welt lower pocket'},
                    {value: 'Double besom lower pocket', text: 'Double besom lower pocket'}
                ],
                breastPocket: [
                    {value: '', text: 'Please select'},
                    {value: 'Welt lower pocket with breast pocket', text: 'Welt lower pocket with breast pocket'},
                    {
                        value: 'Double besom lower pocket with breast pocket',
                        text: 'Double besom lower pocket with breast pocket'
                    },
                    {
                        value: 'Double besom lower pocket with Welt pocket',
                        text: 'Double besom lower pocket with Welt pocket'
                    }
                ],
                inner: [
                    {value: '', text: 'Please select'},
                    {value: 'With Appointed lining- ', text: 'With Appointed lining- '},
                    {value: 'Matching fabric color lining- ', text: 'Matching fabric color lining- '}
                ],
                back: [
                    {value: '', text: 'Please select'},
                    {value: 'Back with strap& lining- ', text: 'Back with strap& lining- '},
                    {value: 'Back with strap& back use fabric', text: 'Back with strap& back use fabric'},
                    {value: 'Back no strap & back use fabric', text: 'Back no strap & back use fabric'},
                    {value: 'Back no strap& lining- ', text: 'Back no strap& lining- '}
                ],
                tuxedo: [
                    {value: '', text: 'Please select'},
                    {value: 'Satin lower pocket besom', text: 'Satin lower pocket besom'},
                    {value: 'Satin covered button', text: 'Satin covered button'}
                ]
            }
        },
        embroidery: {
            jacket: {
                position: [
                    {value: '', text: 'Please select'},
                    {value: 'Collar Felt', text: 'Collar Felt'},
                    {value: 'Above right inner pocket', text: 'Above right inner pocket'}
                ],
                color: [
                    {value: '', text: 'Please select'},
                    {value: '101 - cream white', text: '101 - cream white'},
                    {value: '102 - white', text: '102 - white'},
                    {value: '1017 - light yellow', text: '1017 - light yellow'},
                    {value: '103 - black', text: '103 - black'},
                    {value: '1034 - dark pink', text: '1034 - dark pink'},
                    {value: '1059 - turquoise', text: '1059 - turquoise'},
                    {value: '1093 - turquoise', text: '1093 - turquoise'},
                    {value: '1116 - green', text: '1116 - green'},
                    {value: '1161 - medium brown', text: '1161 - medium brown'},
                    {value: '1162 - dark brown', text: '1162 - dark brown'},
                    {value: '1173 - dark olive', text: '1173 - dark olive'},
                    {value: '1196 - jujube red', text: '1196 - jujube red'},
                    {value: '1203 - sapphire blue', text: '1203 - sapphire blue'},
                    {value: '138 - red', text: '138 - red'},
                    {value: '144 - dark red', text: '144 - dark red'},
                    {value: '145 - burgandy', text: '145 - burgandy'},
                    {value: '177 - light purple', text: '177 - light purple'},
                    {value: '189 - violet', text: '189 - violet'},
                    {value: '262 - light olive', text: '262 - light olive'},
                    {value: '3077 - medium yellow', text: '3077 - medium yellow'},
                    {value: '3099 - light brown', text: '3099 - light brown'},
                    {value: '312 - yolk yellow', text: '312 - yolk yellow'},
                    {value: '3172 - yellow', text: '3172 - yellow'},
                    {value: '321 - orange', text: '321 - orange'},
                    {value: '3334 - yellow green', text: '3334 - yellow green'},
                    {value: '352 - white yellow', text: '352 - white yellow'},
                    {value: '3601 - purple', text: '3601 - purple'},
                    {value: '3618 - grey', text: '3618 - grey'},
                    {value: '3655 - navy blue', text: '3655 - navy blue'},
                    {value: '3687 - medium grey', text: '3687 - medium grey'},
                    {value: '3694 - blackish green', text: '3694 - blackish green'},
                    {value: '3701 - light pink', text: '3701 - light pink'},
                    {value: '3712 - navy blue', text: '3712 - navy blue'},
                    {value: '3720 - navy blue grey', text: '3720 - navy blue grey'},
                    {value: '3742 - dark purple', text: '3742 - dark purple'},
                    {value: '3747 - light grey', text: '3747 - light grey'},
                    {value: '3750 - slivery grey', text: '3750 - slivery grey'},
                    {value: '3813 - medium green', text: '3813 - medium green'},
                    {value: '392 - khahi', text: '392 - khahi'},
                    {value: '432 - light blue', text: '432 - light blue'},
                    {value: '455 - dark blue', text: '455 - dark blue'},
                    {value: '489 - dark grey', text: '489 - dark grey'},
                    {value: '572 - dark green', text: '572 - dark green'},
                    {value: '598 - taupe', text: '598 - taupe'},
                    {value: '599 - blue', text: '599 - blue'},
                    {value: '624 - pink', text: '624 - pink'},
                    {value: '633 - blue purple', text: '633 - blue purple'},
                    {value: '714 - bright red', text: '714 - bright red'},
                    {value: 'MG2 - gold', text: 'MG2 - gold'},
                    {value: 'MS1 - slivery', text: 'MS1 - slivery'}
                ],
                font: [
                    {value: '', text: 'Please select'},
                    {value: 'Calligraphy', text: 'Calligraphy'},
                    {value: 'Times Roman', text: 'Times Roman'},
                    {value: 'Block', text: 'Block'},
                    {value: 'Script', text: 'Script'}
                ]
            }
        },
        bodyDescription: {
            frontShoulder: [
                {value: '', text: 'Please select'},
                {value: 'Very backward', text: 'Very backward'},
                {value: 'Normal', text: 'Normal'},
                {value: 'Slight forward', text: 'Slight forward'},
                {value: 'Very forward', text: 'Very forward'}
            ],
            backBody: [
                {value: '', text: 'Please select'},
                {value: 'Body forward 1.0', text: 'Body forward 1.0'},
                {value: 'Body forward 0.8', text: 'Body forward 0.8'},
                {value: 'Body forward 0.5', text: 'Body forward 0.5'},
                {value: 'Normal', text: 'Normal'},
                {value: 'Body backward 0.5', text: 'Body backward 0.5'},
                {value: 'Body backward 0.8', text: 'Body backward 0.8'},
                {value: 'Body backward 1.0', text: 'Body backward 1.0'},
                {value: 'Body backward 1.5', text: 'Body backward 1.5'}
            ],
            shoulder: [
                {value: '', text: 'Please select'},
                {value: 'G - High 1.5', text: 'G - High 1.5'},
                {value: 'F - Half High 1.0', text: 'F - Half High 1.0'},
                {value: 'E - Slight High 0.5', text: 'E - Slight High 0.5'},
                {value: 'A - Normal', text: 'A - Normal'},
                {value: 'B - Slight Slope 0.5', text: 'B - Slight Slope 0.5'},
                {value: 'C - Mod Slope 1.0', text: 'C - Mod Slope 1.0'},
                {value: 'D - Very Slope 1.5', text: 'D - Very Slope 1.5'}
            ],
            arm: [
                {value: '', text: 'Please select'},
                {value: 'Very Backward', text: 'Very Backward'},
                {value: 'Slight Backward', text: 'Slight Backward'},
                {value: 'Normal', text: 'Normal'},
                {value: 'Very Arms Forward', text: 'Very Arms Forward'},
                {value: 'Curved Arm', text: 'Curved Arm'},
                {value: 'Straight Arm', text: 'Straight Arm'},
            ],
            belly: [
                {value: '', text: 'Please select'},
                {value: 'Normal', text: 'Normal'},
                {value: 'Portly', text: 'Portly'},
                {value: 'Belly Tenesmic', text: 'Belly Tenesmic'},
                {value: 'Bend Fwrd Abs & B', text: 'Bend Fwrd Abs & B'},
                {value: 'Normal Belly Fat', text: 'Normal Belly Fat'},
            ],
            pantHip: [
                {value: '', text: 'Please select'},
                {value: 'Prominent', text: 'Prominent'},
                {value: 'Normal', text: 'Normal'},
                {value: 'Flat', text: 'Flat'},
                {value: 'Drop', text: 'Drop'},
            ]
        },
        orderStatus: [
            {value: '', text: 'Please select'},
            {value: 0, text: 'Saved'},
            {value: 1, text: 'In Production'},
            {value: 2, text: 'Pending Delivery'},
            {value: 3, text: 'Completed'}
        ]
    },
    dataListOption: {
        fabric: [
            'Black- 265g',
            'Black- 280G',
            'blue check 265g',
            'light blue',
            'solid - white'
        ],
        lining: [
            '1  FLL181',
            '3  FLL7142-5',
            '4  FLL7142-1',
            '11  FLL185',
            '12  FLL186',
            '13  FLL148',
            '16  FLL150',
            '19  FLL3002-1',
            '20  FLL3002-9',
            '21  FLL3002-10',
            '23  FLL178',
            '24  FLL179',
            '25  FLL621-587',
            '26  FLL621-003',
            '27  FLL621-302',
            '28  FLL8808N-41088',
            '29  FLLF502-213',
            '30  FLLF502-237',
            '31  FLL8808N-35',
            '32  FLL622-430',
            '33  FLL623-HS',
            '34  FLLF502-210',
            '36  FLL622-083',
            '39  FLL8808N-509',
            '40  FLL621-4927',
            '41  FLL8808N-11',
            '44  FLL8808N-811',
            '45  FLL622-072',
            '47  FLL622-071',
            '48  FLL622-BK',
            '49  FLLF503-62',
            '50  FLL2006-70',
            '51  FLL623-26',
            '52  FLL623-23',
            '53  FLL2006-35',
            '54  FLL621-4035',
            '55  FLL623-24',
            '56  FLL623-113',
            '57  FLL623-27',
            '58  FLL623-NM706',
            '59  FLL623-29',
            '60  FLL624-129',
            '61  FLL624-130',
            '62  FLL624-131',
            '63  FLL624-132',
            '64  FLL0388-810',
            '66  FLL624-135',
            '67  FLL624-133',
            '68  FLL624-056',
            '69  FLL624-043',
            '70  FLL624-044',
            '71  FLL624-027',
            '73  FLL624-059',
            '75  FLL624-040',
            '76  FLL624-041',
            '77  FLL624-126',
            '78  FLL624-120',
            '79  FLL624-111',
            '81  FLL624-123',
            '82  FLL624-121',
            '83  FLL624-122',
            '84  FLL3223-512',
            '85  FLL3223-47',
            '86  FLL3223-120',
            '87  FLL2019-121',
            '88  FLL2019-812',
            '89  FLL2019-20',
            '90  FLL2019-5654',
            '91  FLL2019-132',
            '101  FLL624-048',
            '102  FLL624-050',
            '103  FLL624-049',
            '106  FLL624-024',
            '108  FLLPV106-3',
            '109  FLL624-017',
            '110  FLL624-020',
            '111  FLL624-022',
            '112  FLLPV106-6',
            '113  FLL624-010',
            '114  FLLPV8161-63',
            '122  FLL624-118',
            '125  FLL86742-1',
            '126  FLL86742-2',
            '127  FLL621-040',
            '128  FLL603-E',
            '129  FLLVPX116',
            '130  FLL621-23',
            '131  FLL624-106',
            '133  FLLYK580-16',
            '134  FLLYK580-28',
            '141  FLLYK1780-110',
            '143  FLLYK1780-139',
            '144  FLLYK1780-149',
            '145  FLLYK1780-187',
            '146  FLLYK1780-198',
            '147  FLLYK1780-197',
            '148  FLLYK1780-200',
            '149  FLLYK1790-45',
            '151  FLLYK1790-42',
            '152  FLLYK1790-39',
            '154  FLLYK1790-490',
            '157  FLLYK1790-47',
            '181  FLL9727-11',
            '182  FLL9727-BK',
            '187  FLLDL002',
            '188  FLLDL008',
            '189  FLLDL009',
            '190  FLLDL015',
            '191  FLLDL033',
            '192  FLLDL017',
            '193  FLLDL031',
            '196  FLLDL014',
            '197  FLLDL038',
            '200  FLLDL013',
            '201  FLLDL037',
            '202  FLLDL025',
            '212  FLLDL028',
            '216  FLLDL044',
            '217  FLLDL045',
            '218  FLLDL046',
            '219  FLLDL047',
            '220  FLLDL048',
            '225  FLLDL053',
            '226  FLLDL054',
            '227  FLLDL055',
            '228  FLLDL056',
            '231  FLLDL019',
            '232  FLLDL029',
            '234  FLLDL039',
            '236  FLLDL063',
            '237  FLLDL062',
            '238  FLLDL061',
            '239  FLLDL060',
            '240  FLLDL059',
            '241  FLL217',
            '242  FLL218',
            '243  FLL219',
            '244  FLL220',
            '245  FLLYK1780-168',
            '246  FLLYK1780-255',
            '247  FLLYK1650-143',
            '248  FLLYK1650-210',
            '249  FLLYK1650-760',
            '250  FLLYK1650-4032',
            '251  FLLYK1650-826',
            '252  FLLYK1650-0915',
            '253  FLLYK1650-81',
            '254  FLLYK1650-99',
            '255  FLLDL067',
            '256  FLLDL071',
            '257  FLLDL072',
            '258  FLLDL073',
            '259  FLLDL075',
            '260  FLLDL076',
            '261  FLLDL077',
            '262  FLLDL078',
            '263  FLLDL068',
            '264  FLLDL069',
            '265  FLLDL070',
            '266  FLLDL079',
            '267  FLLDL080',
            '268  FLLDL081'
        ]
    }
}